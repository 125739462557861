<template>
  <div>
    <Nav />
    <div class="testSearch-wrap pageMain">
      <div class="main">
        <div class="title">
          <div>{{ titleObj.title1 }}</div>
          <div>{{ titleObj.title2 }}</div>
        </div>
        <div class="testSearch-form">
          <div>
            <div>
              <div class="label">姓名</div>
              <div class="input-wrap">
                <input
                  type="text"
                  v-model="form.name"
                  placeholder="请输入您的姓名"
                />
              </div>
            </div>
            <div class="mt15">
              <div class="label">证件号（身份证、台胞证、护照及其他）</div>
              <div class="input-wrap">
                <input
                  type="text"
                  v-model="form.idNumber"
                  @keyup.enter="search"
                  placeholder="请输入您的证件号"
                />
              </div>
            </div>
          </div>
          <div class="login-btn" @click="search">登录</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
import { Dialog } from "vant";
export default {
  data() {
    return {
      isIndex: false,
      titleObj: {
        title1: "",
        title2: "",
      },
      form: {
        name: "",
        idNumber: "",
        type: "",
      },
    };
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
    Nav,
    Footer,
  },
  mounted() {
    this.form.type = this.$route.query.type;
    if (this.form.type == "test") {
      this.titleObj = {
        title1: "2022年苏州民族管弦乐团",
        title2: "社会艺术水平考级查询",
      };
    } else if (this.form.type == "teenager") {
      this.titleObj = {
        title1: "第二届苏州市青少年民族器乐展演",
        title2: "查询下载",
      };
    } else if (this.form.type == "teenagerScoreInquiry") {
      this.titleObj = {
        title1: "第二届苏州市青少年民族器乐展演",
        title2: "成绩查询",
      };
    } else if (this.form.type == "testScoreInquiry") {
      this.titleObj = {
        title1: "2022年苏州民族管弦乐团",
        title2: "社会艺术水平考级成绩查询",
      };
    }
  },
  methods: {
    search() {
      if (this.form.name && this.form.idNumber) {
        if (
          this.form.type == "teenagerScoreInquiry" ||
          this.form.type == "testScoreInquiry"
        ) {
          // 成绩查询
          this.scoreInquirySearch();
        } else {
          // 准考证查询
          this.admissionTicketSearch();
        }
      } else {
        this.$toast.fail("请正确输入");
      }
    },
    // 准考证查询
    admissionTicketSearch() {
      let ajaxUrl = "";
      if (this.form.type == "test") {
        ajaxUrl = this.$ajax.localOrOnline() + "examRegistration/registration/info";
      } else {
        ajaxUrl = this.$ajax.localOrOnline() + "instrument/competition/info";
      }
      this.$ajax.get(ajaxUrl, this.form).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            this.$router.push({ name: "TestUserInfo", query: this.form });
          } else {
            this.$dialog
              .alert({
                title: "提示",
                message: "没有获取到您的报名信息",
              })
              .then(() => {});
          }
        } else {
          this.$toast.fail(res.message);
        }
      });
    },
    // 成绩查询
    scoreInquirySearch() {
      let ajaxUrl = "";
      if (this.form.type == "testScoreInquiry") {
        ajaxUrl = this.$ajax.localOrOnline() + "examRegistration/getScore";
      } else {
        ajaxUrl = this.$ajax.localOrOnline() + "instrument/competition/getInstrumentScore";
      }
      this.$ajax
        .get(ajaxUrl, this.form)
        .then((res) => {
          if (res.code == 200) {
            if (res.data && res.data.length) {
              this.$router.push({ name: "ScoreInquiry", query: this.form });
            } else {
              this.$dialog
                .alert({
                  title: "提示",
                  message: "没有获取到您的考试信息",
                })
                .then(() => {});
            }
          } else {
            this.$toast.fail(res.message);
          }
        });
    },
  },
};
</script>
<style scoped>
.testSearch-wrap {
  background-image: url("../../assets/testSearch-bg.png");
  background-size: cover;
  padding: 100px 10px;
}
.testSearch-wrap .main {
  width: 95%;
  box-sizing: border-box;
  opacity: 0.95;
  background: #ffffff;
  border-radius: 12px;
  backdrop-filter: blur(7px);
  padding: 50px 15px;
  margin: 0 auto;
}
.main .title {
  font-size: 20px;
  font-family: STZhongsong, STZhongsong-Regular;
  font-weight: 400;
  text-align: CENTER;
  color: #845c46;
}

.main .testSearch-form {
  margin-top: 45px;
}
.main .label {
  font-size: 16px;
  color: #333333;
}
.main .input-wrap {
  border-bottom: 1px solid #999999;
  padding: 10px 0;
}
.main input {
  width: 100%;
  border: none;
  padding-left: 2px;
}
.main input::-webkit-input-placeholder {
  font-size: 14px;
  color: #999999;
}
.main .login-btn {
  width: 90%;
  line-height: 50px;
  background: #bf783d;
  border-radius: 12px;
  color: #fff;
  text-align: center;
  margin: 50px auto 0;
}
</style>